/* src/WordProcessor.css */

.toolbar {
  margin-bottom: 10px;
  margin: 0;
  padding: 0;
  width: '90%';
}

.toolbar button {
  margin-right: 5px;
  padding: 5px 10px;
  cursor: pointer;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
  color: #007bff;
  font-size: 14px;
}

.toolbar button:hover {
  background-color: #e1e1e1;
}

.editor {
  border: 1px solid #ccc;
  padding: 10px;
  max-height: 300px;
  cursor: text;
  background-color: white;
}

.editor:focus {
  outline: none;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.align-justify {
  text-align: justify;
}
