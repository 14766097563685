/* ChatWindowModern.css */
.chat-window-modern {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 16px;
}

.chat-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.message-area {
  flex: 1;
  overflow-y: auto;
}

.message-bubble {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
}

.message-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.message-bubble.incoming .message-text {
  background-color: #f1f1f1;
}

.message-bubble.outgoing .message-text {
  background-color: #d1eaff;
  align-self: flex-end;
}

.message-text {
  padding: 10px;
  border-radius: 10px;
  max-width: 80%;
  font-size: 16px;
}

.message-time {
  font-size: 12px;
  color: #aaa;
  margin-top: 5px;
}

.chat-input {
  display: flex;
  align-items: center;
  padding: 12px 0;
  border-top: 1px solid #ddd;
}

.input-field {
  flex-grow: 1;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid #ddd;
  margin-right: 10px;
}

.send-button {
  background: none;
  border: none;
  font-size: 24px;
  color: #ff6600;
}
