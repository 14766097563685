/* src/App.css */

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Warang+Citi&display=swap');

body {
  font-family: 'Nunito', sans-serif;
}


.App {
  text-align: center;
  padding: 20px;
}

.controls {
  margin-bottom: 20px;
}

.join-call {
  margin-top: 10px;
}

.videos {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.video-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

video {
  width: 300px;
  height: 200px;
  background-color: #000;
  margin-top: 10px;
  border: 1px solid #ccc;
}

#join, #addAnswer, #remote {
  display: none;
}