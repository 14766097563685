.video-call-container {
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: black;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

/* Fullscreen video */
.fullscreen-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: 0;
  padding: 0;
}

/* Floating small video */
.floating-video {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 30vw;
  height: 30vh;
  border: 2px solid #fff;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  z-index: 2;
}

.small-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.floating-video-controls {
  position: absolute;
  top: 0;
  right: 5px;
  display: flex;
  flex-direction: row;
  z-index: 4;
}

.floating-video-controls button {
  margin-left: 5px;
  padding: 2px;
  border: none;
  background-color: #444;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.floating-video-controls button:hover {
  background-color: #222;
}

/* Minimized state */
.minimized {
  width: 80px;
  height: 60px;
}

.call-controls {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
}

.call-controls button {
  background-color: #444;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
}

.call-controls button:hover {
  background-color: #222;
}

#end-call-button {
  background-color: #d82d21;
  color: white;
}

#end-call-button:hover {
  background-color: darkred;
}