.chat-app {
    display: flex;
    flex-direction: column;
    width: 400px;
    margin: 0 auto;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
}

.chat-window {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
    background-color: #f9f9f9;
}

.chat-message {
    margin-bottom: 10px;
    padding: 5px;
    border-bottom: 1px solid #ddd;
}

.chat-input {
    display: flex;
    flex-direction: column;
    padding: 10px;
    background-color: #fff;
    border-top: 1px solid #ccc;
}

.toolbar {
    display: flex;
    gap: 5px;
    margin-bottom: 10px;
}

.toolbar button {
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
}

textarea {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: none;
    height: 80px;
}

button {
    margin: 0;
    padding: 5px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
}
