/* AppLayout.css */

.app-layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  padding: 0;
  margin: 0;
  background-color: rgb(255, 255, 255); /* Semi-transparent background */
  opacity: 1;
  position: fixed;
  width: 100%;
  top: 0;
}

.header-items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 20px 5px 20px;
}

.app-title {
  font-size: 16px;
  font-weight: 900;
  text-align: center;
  width: 100%;
}

.icons {
  display: flex;
  gap: 16px;
}

.icon {
  font-size: 24px;
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
}

.main-content {
  padding: 85px 0 1px; /* Space for header and footer */
  overflow-y: auto;
  flex-grow: 1;
  background-color: #f3f3f4;
}

.footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 12px;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  position: fixed;
  bottom: 0;
  width: 100%;
}

.footer .icon {
  font-size: 24px;
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
}

.dropdown-button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

/* Styling for the dropdown content */
.dropdown-content {
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  top: 80px;
  left: 75px;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}