/* ChatListModern.css */
.chat-list-modern {
  padding: 16px;
  font-family: 'Helvetica Neue', sans-serif;
}

/*
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
}*/

.icons {
  display: flex;
  gap: 12px;
}

.icon {
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
}

.chat-items {
  margin-top: 20px;
}

.chat-item {
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #f1f1f1;
}

.avatar-container {
  position: relative;
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.unread-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #ff6600;
  color: white;
  border-radius: 50%;
  padding: 4px;
  font-size: 12px;
}

.chat-info {
  margin-left: 12px;
  flex-grow: 1;
}

.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chat-name {
  font-weight: bold;
  color: #333;
}

.chat-time {
  color: #aaa;
}

.chat-message {
  color: #666;
  font-size: 14px;
}

.chat-item .chat-message em {
  color: #ff6600; /* Typing indicator color */
}
