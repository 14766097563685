.screen-share-container, .remote-view-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.fullscreen-shared-screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
}

.floating-video-window, .floating-local-video-window {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 150px;
  cursor: grab;
}

.floating-local-video-window {
  top: 20px;
  left: 20px;
}

.floating-video-window video, .floating-local-video-window video {
  border-radius: 10px;
  border: 1px solid #ccc;
}
