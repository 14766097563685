footer {
    font-size: 90%;
    text-align: left;
}

footer a {
    text-decoration: none;
    color: #32aa27;
}

.contact a {
    text-decoration: none;
    color: #32aa27;
}

.mirror-icon {
  transform: scaleX(-1);
}

.contact-icon {
  margin-right: 5px;
  color: #32aa27;
}

svg.contact-icon.facebook {
  color: #0d6efd;
}

ul.list-unstyled.contact li {
    padding-bottom: 5px;
}

a.no-style {
    color: #fff;
}

a.no-style-dark {
    color: #595959;
}