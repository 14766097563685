/* MessageCard.css */

.message-card {
  display: flex;
  align-items: center;
  padding: 10px 15px 5px;/*
  border-radius: 8px;
  margin-bottom: 10px;*/
}

/* Different background colors for alternating cards */
.message-card.even {
  background-color: #f8f8fa; /* Light grey for even rows */
}

.message-card.odd {
  background-color: #ffffff; /* White for odd rows */
}

.profile-section {
  /*position: relative;*/
  margin-right: 15px;
}

.profile-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.profile-img-main {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  object-fit: cover;
}

.profile-img-user {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.badge {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #ff7f32;
  color: white;
  border-radius: 50%;
  font-size: 12px;
  padding: 4px 6px;
}

.message-details {
  flex: 1;
}

.message-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.name {
  font-weight: 600;
  font-size: 16px;
  color: #333;
}

.time {
  font-size: 12px;
  color: #a6a6a6;
}

.message-preview {
  font-size: 14px;
  color: #a6a6a6;
  margin: 5px 0;
  line-height: 1.4;
}
