.audio-call-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.call-controls {
  margin-top: 20px;
  padding: 5px;
  background-color: #3030312d;
  border-radius: 20px;
}

.top-controls {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin-top: 10px;
  padding: 10px;
}

.call-controls button {
  margin: 1px;
  padding: 10px 12px;
  background-color: #1e1f1f52;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 50%;
}

.top-controls button {
  margin: 1px;
  padding: 10px 12px;
  background-color: #1e1f1f52;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 50%;
}

.call-controls button:hover {
  background-color: #1e1f1fe8;
}

h2 {
  color: #333;
  font-size: 24px;
}

.video-prompt-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-prompt-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 400px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.video-prompt-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.confirm-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.cancel-button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

#end-call-button {
  background-color: #d82d21;
  color: white;
  transform: rotate(135deg);
}

#accept-call-button {
  background-color: #45a049;
  color: white;
}

#hide-call-button {
  transform: scaleX(-1);
}

#add-call-button {
  background-color: #f5f5f5;
  color: #f5f5f5;
  transform: scaleX(-1);
}

#end-call-button:hover {
  background-color: darkred;
}